// Modules
import React, { useState, Fragment } from "react";
import styled from "styled-components";
import axios from "axios";

// Components
import { Button } from "../Button";
import { Loading } from "../Loading";

// Definitions
interface IProps {
  type: string;
  label: string;
  userId: number;
  selected?: string;
  acceptFileTypes?: string;
  onChange: (url: string) => void;
}

// Helper
const createHash = (length: number) => {
  let result = "";
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

// Component / Hook
export const FileUploader = (props: IProps) => {
  // Props
  const {
    type,
    userId,
    onChange,
    selected,
    label,
    acceptFileTypes = ".pdf",
  } = props;

  // States
  const [uploadInput, setUploadInput] = useState();
  const [uploading, setUploading] = useState(false);
  const [linktToFile, setLinktToFile] = useState(selected || "");

  // Helper functions
  const setFileUrl = (url: string) => {
    onChange(url);
    setLinktToFile(url);
  };

  const handleUpload = async () => {
    setUploading(true);

    // Get file - we only support single file upload here
    const file = uploadInput.files[0];

    const fileParts = file.name.split(".");
    const fileType = fileParts[1];

    // Overwrite file name
    const fileName = `${type}_${userId}_${Date.now()}_${createHash(
      30
    )}.${fileType}`;

    const response = await fetch(
      `${process.env.REACT_APP_API_BACKEND}/s3-signing`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ fileName, fileType }),
      }
    );

    // API will return the created user id
    const { signedRequest, url } = await response.json();

    const putRespponse = await axios.put(signedRequest, file, {
      headers: {
        "Content-Type": `application/${fileType}`,
      },
    });

    if (putRespponse.status !== 200) {
      alert("Es ist ein Fehler aufgetreten");
      return;
    }

    setUploading(false);
    setFileUrl(url);
  };

  return (
    <Wrapper>
      {uploading && (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      )}
      {linktToFile.length > 1 && (
        <Fragment>
          <RemoveUpload onClick={() => setFileUrl("")}>x</RemoveUpload>
          <a href={linktToFile} target="_blank" download="notsopainful">
            {label} ansehen
          </a>
        </Fragment>
      )}
      {!linktToFile.length && !uploading && (
        <label>
          <Fragment />
          <StyledInput
            accept={acceptFileTypes}
            ref={(ref: any) => setUploadInput(ref)}
            type="file"
            name="fileUploader"
            id="fileUploader"
            onChange={handleUpload}
          />
          <Button>
            <StyledSVG
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="17"
              viewBox="0 0 20 17"
            >
              <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
            </StyledSVG>{" "}
            Datei auswählen
          </Button>
        </label>
      )}
    </Wrapper>
  );
};

// Styles
const Wrapper = styled.div`
  margin-top: 15px;
`;

const StyledInput = styled.input`
  position: absolute;
  visibility: hidden;
`;

const StyledSVG = styled.svg`
  fill: ${props => props.theme.light};
  margin-right: 5px;
`;

const LoadingWrapper = styled.div`
  max-width: 100px;
`;

const RemoveUpload = styled.span`
  cursor: pointer;
  color: ${props => props.theme.invalidColor};
  margin-right: 10px;
  text-align: center;
  width: 10px;
  display: inline-block;
  position: relative;
  top: -1px;

  &:hover {
    font-weight: bold;
  }
`;
