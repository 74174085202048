// Modules
import React, { ReactChild } from "react";
import styled from "styled-components";

// Styles
const StyledIntro = styled.p`
  line-height: ${props => props.theme.textLineHeight};
  margin: 0 0 15px 0;
`;

// Definitions
type Props = {
  children: ReactChild | Array<ReactChild>;
  className?: string;
};

// Component / Hook
export const Intro = (props: Props) => {
  const { children, className } = props;
  return <StyledIntro className={className}>{children}</StyledIntro>;
};
