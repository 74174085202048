// Modules
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

// Context
import { AppContext } from "../../ContextProvider";

// Components
import { Button } from "../Button";

// Custom
import { menuItems } from "../Navigation";

// Icons
import ArrowRight from "../Icons/ArrowRight";
import ArrowLeft from "../Icons/ArrowLeft";

// Styles
const NavWrapper = styled.nav`
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 50px auto;
`;

const NextButton = styled(Button)``;

const PrevButton = styled(Button)`
  margin-right: 15px;
  color: ${props => props.theme.darkGrey};
  border: 1px solid ${props => props.theme.darkGrey};

  svg {
    fill: ${props => props.theme.darkGrey};
  }
`;

const NextIcon = styled(ArrowRight)`
  position: relative;
  top: 2px;
  left: 15px;
`;

const PrevIcon = styled(ArrowLeft)`
  position: relative;
  top: 1px;
  right: 15px;
`;

const Error = styled.div`
  border: 1px solid ${props => props.theme.backgroundInvalid};
  background: ${props => props.theme.backgroundInvalid};
  color: ${props => props.theme.invalidTextColor};
  border-radius: 4px;
  text-align: center;
  padding: 20px;
  margin-bottom: 25px;
`;

// Component / Hook
const FooterNavigationBase = (props: any) => {
  const {
    prevLabel = "Zurück",
    nextLabel = "Weiter",
    errorText = "Bitte füllen Sie erst alle Pflichtfelder aus.",
    disabledNext,
    step = "",
    history,
    onBeforeNext,
    onBeforePrev,
  } = props;

  // States
  const [showError, setShowError] = useState(false);

  // Context
  const { dispatch } = useContext(AppContext);

  // Get the current step index
  const stepIndex = menuItems.map(menu => menu.key).indexOf(step);

  const goTo = (pathname: string) => {
    history.push({
      pathname,
    });
    scrollToTop();
  };

  const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, c - c / 8);
    }
  };

  const onClickPrev = async () => {
    if (onBeforePrev) {
      const isValid = await onBeforePrev();
      if (!isValid) return;
    }

    prev();
  };

  const prev = () => {
    const newIndex = stepIndex > 1 ? stepIndex - 1 : 0;

    // // Navigate back from 2. step to 1. go directly so select material section
    // if (newIndex === 0) {
    //   dispatch({
    //     stepAction: "reset",
    //     step: "advertisingMaterial",
    //   });
    // }

    goTo(menuItems[newIndex].to);
  };

  const next = () => {
    dispatch({
      stepAction: "done",
      step,
    });
    goTo(menuItems[stepIndex + 1].to);
  };

  const onClickNext = async () => {
    if (disabledNext) {
      setShowError(true);
      return;
    }
    if (onBeforeNext) {
      const isValid = await onBeforeNext();
      if (!isValid) {
        setShowError(true);
        return;
      }
    }

    next();
  };

  return (
    <NavWrapper>
      {showError && <Error>{errorText}</Error>}
      {stepIndex > -1 && (
        <PrevButton onClick={onClickPrev}>
          <PrevIcon />
          {prevLabel}
        </PrevButton>
      )}
      <NextButton onClick={onClickNext}>
        {nextLabel} <NextIcon />
      </NextButton>
    </NavWrapper>
  );
};

export const FooterNavigation = withRouter(FooterNavigationBase);
