// Modules
import React, { useContext, Fragment } from "react";
import styled from "styled-components";

// Context
import { AppContext } from "../../ContextProvider";

// Components
import { Title } from "../Title";
import { Price } from "../Circulation";

// Styles
const Wrapper = styled.div``;

const ColumnWrapper = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.largeMargin};
`;

const Column = styled.div`
  flex: 1;

  &:first-child {
    padding-right: 30px;
  }
`;

const Row = styled.div`
  margin-bottom: 5px;
  display: flex;
`;

const Label = styled.div`
  flex: 1;
  max-width: 220px;
`;

const Value = styled.div`
  flex: 1;
`;

const UserWrapper = styled.div``;

// Component / Hook
export const Summary = () => {
  // Context
  const {
    state: {
      targetGroups,
      shippingCategories,
      competitiveInformation,
      userData,
    },
  } = useContext(AppContext);

  const displayVatTax = userData.vatId && userData.vatId.length ? `USt-ID: ${userData.vatId}` : `Steuernummer: ${userData.taxno}`
  const displaySaluation = userData.salutation === 'male' ? 'Herr' : 'Frau';

  return (
    <Wrapper>
      <ColumnWrapper>
        <Column>
          <Title label="Zusammenfassung" isSmall />
          <Row>
            <Label>Zielgruppe:</Label>
            <Value>{targetGroups.selectedValues.join(", ")}</Value>
          </Row>
          <Row>
            <Label>Versandkategorien:</Label>
            <Value>{shippingCategories.selectedValues.join(", ")}</Value>
          </Row>
          <Row>
            <Label>Beworbenes Angebot:</Label>
            <Value>
              {competitiveInformation.brand &&
                competitiveInformation.brand.length && (
                  <Fragment> {competitiveInformation.brand}</Fragment>
                )} ({competitiveInformation.categoryName})
            </Value>
          </Row>
        </Column>
        <Column>
          <Title label="Auftraggeber" isSmall />
          <UserWrapper>
            {userData.company}<br />
            {displayVatTax}<br />
            {userData.co}<br />
            {userData.streetMore && userData.streetMore.length && (
              <Fragment>
                <br />
                {userData.streetMore}
              </Fragment>
            )}
            {userData.street} {userData.streetNumber}<br />
            {userData.zipcode} {userData.city}<br />
            Deutschland<br />
            <br />
            {displaySaluation} {userData.title} {userData.firstname}{" "}
            {userData.lastname}<br />
            {userData.email}<br />
            {userData.phone}
          </UserWrapper>
        </Column>
      </ColumnWrapper>
      <Price />
    </Wrapper>
  );
};
