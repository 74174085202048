// Modules
import React, { ReactChild } from "react";
import styled from "styled-components";
import { useResponsive } from "react-hooks-responsive";

// Config
import { breakpoints } from "../../config";

// Container
import { MainContainer } from "../../containers/Main";

// Definitions
type IProps = {
  children: ReactChild | ReactChild[];
  className?: string;
  size?: string;
};

// Component / Hook
export const StickyHeaderContent = (props: IProps) => {
  const { children } = props;
  const [size] = useResponsive(breakpoints);

  return (
    <Sticky>
      <StyledMainContainer size={size}>{children}</StyledMainContainer>
    </Sticky>
  );
};

// Styles
const Sticky = styled.div`
  position: fixed;
  top: 260px;
  z-index: 2;
  left: 0px;
  right: 0px;
  background: ${props => props.theme.light};
`;

// Styles
const StyledMainContainer = styled(MainContainer)<IProps>`
  padding: ${props => {
    if (props.size === "md") return "0";
    return "0 20px";
  }};
  max-width: 1260px;
`;
