import * as React from "react";

// Custom
import { menuItems } from "./components/Navigation";

// Definitions
interface State { }
interface Action {
  step: string;
  payload: object;
  stepAction?: string;
}

const TIME_OUT_MILLISECONDS = 30 * (1 + 1/*+ Math.random()*/) * 60 * 1000;

// Settings
const LOCAL_STORAGE_KEY = "pacvertise:state";
const storedStateString = localStorage.getItem(LOCAL_STORAGE_KEY);

// Create defaults from navigation / routing items
const defaultFinishedSections: any = {};
menuItems.forEach(menu => {
  defaultFinishedSections[menu.key] = false;
});

const defaultState: any = {
  config: {},
  targetGroups: {
    selected: [],
    selectedValues: [],
  },
  shippingCategories: {
    selected: [],
    selectedValues: [],
  },
  competitiveInformation: {
    brand: "",
  },
  circulation: {},
  userData: {
    company: "",
    street: "",
    streetMore: "",
    streetNumber: "",
    zipcode: "",
    vatId: "",
    city: "",
    title: "",
    firstname: "",
    lastname: "",
    email: "",
    emailConfirm: "",
    phone: "",
    salutation: "",
    acceptDSE: true,
  },
  finishedSections: defaultFinishedSections,
  updatedAt: new Date(),
};

const storedState = JSON.parse(storedStateString || "{}");

const restart = () => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(defaultState));
  // window.alert("Session ist abgelaufen");
  window.location.href = "/";
};

const checkTimeout = () => {
  const now = new Date().getTime();
  const last = new Date(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || "{}").updatedAt || now
  ).getTime();
  if (now - last >= TIME_OUT_MILLISECONDS) restart();
};

checkTimeout();
window.setInterval(checkTimeout, TIME_OUT_MILLISECONDS + 1 * 1000);

const initialState = storedState.updatedAt ? storedState : defaultState;

// Context
const AppContext = React.createContext(initialState);

// Main redcuer
const reducer: React.Reducer<State, Action> = (state: any, action: any) => {
  const { step = "", payload = {}, stepAction } = action;

  // Create new dynamic object
  const currentState: any = {};
  currentState[step] = {
    ...(state[step] || {}),
    ...payload,
  };

  // Set the new state
  let newState = {
    ...state,
    ...currentState,
  };

  // Check if step should be deleted or reset
  if (stepAction === "reset") {
    newState[step] = defaultState[step] || {};
    newState.finishedSections[step] = false;
  }

  if (stepAction === "completed") {
    newState = defaultState;
    newState.booking = state.booking;
  }

  // Check if step is done
  if (stepAction === "done") {
    newState.finishedSections[step] = true;
  }

  // Save into local storage
  newState.updatedAt = new Date();
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newState));
  return newState;
};

const ContextProvider = (props: any) => {
  const [state, dispatch] = React.useReducer<React.Reducer<State, Action>>(
    reducer,
    initialState
  );
  let value = { state, dispatch };

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
};

const AppContextConsumer = AppContext.Consumer;

export { AppContext, ContextProvider, AppContextConsumer };
