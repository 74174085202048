// Modules
import React, { useContext, useState, useEffect, Fragment } from "react";
import styled from "styled-components";

// Context
import { AppContext } from "../../ContextProvider";

// Components
import { Loading } from "../../components/Loading";

// Helper
export const formatPrice = (number: number) =>
  new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  }).format(number);

// Component / Hook
export const Price = () => {
  // Context
  const {
    state: {
      advertisingMaterial: { type, format, codetype = 0, codetypeName, selectedId, weight },
      circulation: { amount: selectedAmount, amountId, feedback = false },
    },
  } = useContext(AppContext);

  // States
  const [isLoading, setLoading] = useState(true);
  const [totalGrossPrice, setTotalGrossPrice] = useState(-1);
  const [totalNetPrice, setTotalNetPrice] = useState(-1);
  const [tax, setTax] = useState(-1);
  const [totalTax, setTotalTax] = useState(-1);
  const [rows, setRows] = useState([]);

  // Data handling
  const getPrice = async () => {
    setLoading(true);

    let requestURL = `${
      process.env.REACT_APP_API_BACKEND
      }/price?type=${type}&id=${amountId}&selectedId=${selectedId}&amount=${selectedAmount}&format=${format}&type=${type}&codetype=${codetypeName}&weight=${weight}`;

    // Calculate valid feedback
    let hasVoucher = false;

    if (type === "ep" && codetype === 99) {
      hasVoucher = true;
    } else if (
      type === "pp" &&
      (codetype === 1 || codetype === 2 || codetype === 3)
    ) {
      hasVoucher = true;
    }

    if (hasVoucher && feedback) {
      requestURL = `${requestURL}&feedback=true`;
    }

    const response = await fetch(requestURL);
    const resp = await response.json();

    const {
      totalGrossPrice,
      totalNetPrice,
      tax,
      taxTotal,
      rows,
    } = resp;
    setTotalGrossPrice(totalGrossPrice);
    setTotalNetPrice(totalNetPrice);
    setTax(tax);
    setTotalTax(taxTotal);
    setRows(rows);
    setLoading(false);
  };

  // Load data on start
  useEffect(() => {
    getPrice();
  }, [amountId, feedback]);

  return (
    <Fragment>
      {isLoading ? (
        <Loading />
      ) : (
          <Fragment>
            <TableWrapper>
              <thead>
                <TableRow>
                  <TableColumnHeader>Pos</TableColumnHeader>
                  <TableColumnHeader>Bezeichnung</TableColumnHeader>
                  <TableColumnHeader>Menge</TableColumnHeader>
                  <TableColumnHeader>Pro tausend Stück</TableColumnHeader>
                  <TableColumnHeader>Gesamt</TableColumnHeader>
                </TableRow>
              </thead>
              <tbody>
                {rows.map((row: any) => {
                  return <TableRow key={`table-row-${row.index}`}>
                    <TableColumn>{row.index}.</TableColumn>
                    <TableColumn>{row.desc}</TableColumn>
                    <TableColumn>{row.amount.replace(',', '.')}</TableColumn>
                    <TableColumnPrice>{row.priceTKP}</TableColumnPrice>
                    <TableColumnPrice>{row.price}</TableColumnPrice>
                  </TableRow>
                })}
              </tbody>
            </TableWrapper>
            <Summary>
              <SummaryRow>
                <SummaryLabel>
                  <b>Gesammtsumme netto</b>
                </SummaryLabel>
                <SummaryPriceRow>
                  <b>{formatPrice(totalNetPrice)}</b>
                </SummaryPriceRow>
              </SummaryRow>
              <SummaryRow>
                <SummaryLabel>Umsatzsteuer ({tax} %)</SummaryLabel>
                <SummaryPriceRow>{formatPrice(totalTax)}</SummaryPriceRow>
              </SummaryRow>
              <SummaryRow>
                <SummaryLabel>Gesamtsumme brutto</SummaryLabel>
                <SummaryPriceRow>{formatPrice(totalGrossPrice)}</SummaryPriceRow>
              </SummaryRow>
            </Summary>
          </Fragment>
        )}
    </Fragment>
  );
};

// Styles
const TableWrapper = styled.table`
  width: 100%;
`;

const TableRow = styled.tr``;

const TableColumn = styled.td`
  flex: 1;
  background: ${props => props.theme.backgroundLight};
  border-right: 4px solid white;
  border-bottom: 4px solid white;
  padding: 10px 15px;
  max-width: calc(100% / 5);
`;

const TableColumnPrice = styled(TableColumn)`
  text-align: right;
  white-space: nowrap;
`;

const TableColumnHeader = styled(TableColumn)`
  background: #9b9b9b;
  color: ${props => props.theme.light};
`;

const Item = styled.div`
  flex: 1;
`;

const PriceRow = styled(Item)`
  max-width: 150px;
  text-align: right;
`;

const Row = styled.div`
  display: flex;
  padding: 10px ${props => props.theme.defaultMargin};
`;

const Summary = styled.div`
  margin-top: ${props => props.theme.defaultMargin};
`;

const SummaryRow = styled(Row)`
  padding: 2px 10px;
`;

const SummaryPriceRow = styled(PriceRow)`
  padding: 2px 10px;
`;

const SummaryLabel = styled(Item)`
  padding: 0;
  justify-content: flex-end;
  display: flex;
  padding-right: ${props => props.theme.largeMargin};
`;
