// Modules
import React from "react";
import styled from "styled-components";

// Definitions
interface IProps {
  className?: string;
}

// Styles
const StyledSVG = styled.svg`
  fill: ${props => props.theme.light};
`;

// Component
export default (props: IProps) => {
  const { className = "" } = props;

  return (
    <StyledSVG width="9" height="14" viewBox="0 0 9 14" className={className}>
      <path d="M8.40625 7.65137L2.69531 13.3623C2.49935 13.5583 2.26139 13.6562 1.98145 13.6562C1.7015 13.6562 1.46354 13.5583 1.26758 13.3623L0.34375 12.3965C0.147786 12.2005 0.0498047 11.9626 0.0498047 11.6826C0.0498047 11.4027 0.147786 11.1647 0.34375 10.9688L4.375 6.9375L0.34375 2.90625C0.147786 2.71029 0.0498047 2.47233 0.0498047 2.19238C0.0498047 1.91243 0.147786 1.67448 0.34375 1.47852L1.26758 0.512695C1.46354 0.316732 1.7015 0.21875 1.98145 0.21875C2.26139 0.21875 2.49935 0.316732 2.69531 0.512695L8.40625 6.22363C8.60221 6.4196 8.7002 6.65755 8.7002 6.9375C8.7002 7.21745 8.60221 7.4554 8.40625 7.65137Z" />
    </StyledSVG>
  );
};
