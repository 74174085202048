// Modules
import React from "react";
import styled from "styled-components";

// Styles
// const StyledTitleH1ORIGINAL = styled.h1`
//   font-style: normal;
//   font-weight: bold;
//   font-size: 50px;
//   line-height: 50px;
//   margin-bottom: 30px;
// `;

const StyledTitleH1 = styled.h1<Props>`
  font-size: 28px;
  line-height: 39px;
  color: ${props => props.theme.light};
  background: ${props => props.theme.dark};
  display: inline-block;
  margin: 0 0 20px 0;
  padding: 0 5px;
`;

const StyledTitleH3 = styled.h3<Props>`
  font-size: 16px;
  line-height: 21px;
  color: ${props => props.theme.light};
  background: ${props => props.theme.dark};
  display: inline-block;
  margin: 0 0 20px 0;
  padding: 0 5px;
`;

// Definitions
type Props = {
  label?: string;
  isSmall?: boolean;
  className?: string;
};

// Component / Hook
export const Title = (props: Props) => {
  const { label, isSmall, className } = props;

  // Check which type to use
  const TitleComponent = props.isSmall ? StyledTitleH3 : StyledTitleH1;

  return (
    <TitleComponent className={className} isSmall={isSmall}>
      {label}
    </TitleComponent>
  );
};
