// Modules
import React, { useContext } from "react";
import styled from "styled-components";
import { useResponsive } from "react-hooks-responsive";

// Context
import { AppContext } from "../../ContextProvider";

// Config
import { breakpoints } from "../../config";

// Components
import { Title } from "../Title";
import { Checkbox } from "../Checkbox";

// Styles
const StyledSection = styled.section<{ size: string }>`
  margin-bottom: ${props =>
    props.size === "md" ? 0 : props.theme.defaultMargin};
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${props => props.theme.defaultMargin};
`;

const CheckboxWrapper = styled.div<{ size: string }>`
  flex: 1 0 ${props => (props.size === "md" ? "33%" : "50%")};
  max-width: ${props => (props.size === "md" ? "33%" : "50%")};
`;

// Definitions
type Props = {
  sub: any;
  title: string;
  code: string;
};

// Component / Hook
export const ShippingCategoriesGroup = (props: Props) => {
  // Props
  const { title, sub, code } = props;

  // Context
  const {
    dispatch,
    state: { shippingCategories },
  } = useContext(AppContext);

  // States
  const [size] = useResponsive(breakpoints);

  const onSelect = (options: any) => {
    const label = options.target.dataset.label;
    const value = parseInt(options.target.value, 10);
    const isChecked = options.target.checked;
    const currentSelected = shippingCategories.selected;
    const currentSelectedValues = shippingCategories.selectedValues;
    const index = currentSelected.indexOf(value);

    if (isChecked) {
      const labelSuffix =
        code === "1990" ? " (m)" : code === "2487" ? " (w)" : "";
      currentSelected.push(value);
      currentSelectedValues.push(`${label}${labelSuffix}`);
    } else {
      currentSelected.splice(index, 1);
      currentSelectedValues.splice(index, 1);
    }

    dispatch({
      payload: { selected: currentSelected },
      step: "shippingCategories",
    });
  };

  return (
    <StyledSection size={size}>
      <Title label={title} isSmall />
      <CheckboxContainer>
        {sub.map((subItem: any) => (
          <CheckboxWrapper key={`${name}_${subItem.id}_overview`} size={size}>
            <Checkbox
              label={subItem.name}
              name={`${name}_${subItem.id}`}
              key={`${name}_${subItem.id}`}
              value={subItem.code}
              onSelect={onSelect}
              checked={shippingCategories.selected.indexOf(subItem.code) > -1}
            />
          </CheckboxWrapper>
        ))}
      </CheckboxContainer>
    </StyledSection>
  );
};
