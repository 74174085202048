// Modules
import React, { Fragment, useState, useContext } from "react";
import styled from "styled-components";

// Context
import { AppContext } from "../../ContextProvider";

// Components
import { Checkbox } from "../../components/Checkbox";
import { FooterNavigation } from "../../components/FooterNavigation";
import { TextField } from "../../components/TextField";
import { Title } from "../../components/Title";
import { SelectField } from "../../components/SelectField";
import { StickyHeaderContent } from "../../components/StickyHeaderContent";

// Helper
import checkVATId from "./checkVATId";
import checkTaxNo from "./checkTaxNo";
import { isValidEmail } from "./checkEmail";

// Styles
const ColumnWrapper = styled.div`
  display: flex;
`;

const Column = styled.div`
  flex: 1;

  &:first-child {
    padding-right: 30px;
  }
`;

const Error = styled.div`
  color: ${props => props.theme.invalidTextColor};
  margin-top: 5px;
  padding-left: 4px;
`;

const SelectWrapper = styled.div`
  margin-bottom: ${props => props.theme.defaultMargin};
  max-width: 400px;
`;

const Label = styled.div`
  margin-bottom: 5px;
  padding-left: 5px;
`;

const StyledSelectField = styled(SelectField)`
  margin-bottom: ${props => props.theme.defaultMargin};
  width: 800px;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-top: ${props => props.theme.defaultMargin};
`;

// Helper
const checkInvalidFields = (form: any, acceptConditions: boolean) => {
  let invalidFields: any = [];

  // Length checks
  [
    "company",
    "street",
    "streetNumber",
    "zipcode",
    "city",
    "firstname",
    "lastname",
    "phone",
    "salutation",
  ].forEach((key: string) => {
    if (!form[key] || !form[key].length) {
      invalidFields.push(key);
    }
  });

  if ((form.vatId || "").length && !checkVATId(form.vatId))
    invalidFields.push("vatId");
  if ((form.taxno || "").length && !checkTaxNo(form.taxno))
    invalidFields.push("taxno");

  if (!(form.vatId || "").length && !(form.taxno || "").length) {
    invalidFields.push("vatId");
    invalidFields.push("taxno");
  }

  // Check zip code
  if (!/^\d{5}$/.test(form.zipcode)) invalidFields.push("zipcode");

  // Check if email is valid
  if (!isValidEmail(form.email)) invalidFields.push("email");
  // if (!isValidEmail(form.emailConfirm)) invalidFields.push("email");
  // Confirm email if they are the same
  if (form.email !== form.emailConfirm || !isValidEmail(form.emailConfirm)) {
    invalidFields.push("emailConfirm");
  }
  // @ts-ignore-start
  if (
    !(
      /^[+0].+/.test(form.phone) && (form.phone.match(/(\d)/g) || []).length > 5
    )
  )
    invalidFields.push("phone");
  // @ts-ignore-end

  // Check if AGB is checked
  if (!acceptConditions) invalidFields.push("acceptDSE");

  return invalidFields;
};

// Component / Hook
export const UserData = () => {
  // Context
  const {
    dispatch,
    state: { userData },
  } = useContext(AppContext);

  // States
  const [form, setForm] = useState(userData);
  const [validate, setValidate] = useState(false);
  const [invalidFields, setInvalidFields] = useState<any>([]);
  const [acceptConditions, setAcceptConditions] = useState(false);

  const setValue = (props: any) => {
    return new Promise(resolve => {
      let { value, fieldId } = props;
      const copyForm: any = { ...form };

      // Special case for email -> always save lowercase
      if (fieldId === "email" || fieldId === "emailConfirm") {
        value = value.toLowerCase();
      }

      // Set the value
      copyForm[fieldId] = value;
      setForm(copyForm);

      // Check all fields
      setInvalidFields(checkInvalidFields(copyForm, acceptConditions));
      resolve();
    });
  };

  // Select field for title
  let selectedSalutation;

  const selectOptions = [
    { value: "female", label: "Frau" },
    { value: "male", label: "Herr" },
  ];

  selectOptions.forEach(selectItem => {
    if (selectItem.value === form.salutation) {
      selectedSalutation = selectItem;
    }
  });

  const handleSalutationChange = async (selectedOption: any) => {
    const { value } = selectedOption;
    await setValue({ value, fieldId: "salutation" });
  };

  const saveUserData = (form: any) =>
    new Promise(async resolve => {
      const config = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_BACKEND}/users`,
        config
      );

      // API will return the created user id
      const { id } = await response.json();

      // Create payload with whole user data
      const payload = { ...form, id };

      // Save user id in store
      dispatch({
        payload,
        step: "userData",
      });

      resolve();
    });

  const validateAndSave = () =>
    new Promise(async resolve => {
      // Check all fields
      const invalidFields = checkInvalidFields(form, acceptConditions);
      setInvalidFields(invalidFields);

      // Validate after first time click on next button
      setValidate(true);

      console.log(invalidFields);

      // Check if state is invalid
      if (
        invalidFields.length > 0 ||
        !((form.vatId || "").length || (form.taxno || "").length)
      )
        return resolve(false);

      // Save user
      form.acceptDSE = acceptConditions;
      await saveUserData(form);

      // Answer correctly to footer navigation can move to next element
      resolve(true);
    });

  const noTaxInfo = !((form.vatId || "").length || (form.taxno || "").length);

  return (
    <Fragment>
      <StickyHeaderContent>
        <Title label="Ihre Daten" />
      </StickyHeaderContent>
      <ColumnWrapper>
        <Column>
          <Title label="Firma" isSmall />
          <TextField
            label="Firmenname"
            value={form.company}
            mandatory
            fieldId="company"
            onChange={setValue}
            validate={validate}
            isValid={invalidFields.indexOf("company") === -1}
          />
          <TextField
            label="USt-ID (oder Steuernummer)"
            value={form.vatId}
            mandatory
            fieldId="vatId"
            onChange={setValue}
            validate={validate}
            isValid={invalidFields.indexOf("vatId") === -1}
            errorMessage={
              noTaxInfo
                ? "Bitte geben Sie mindestens hier eine USt-ID oder unten eine Steuernummer ein."
                : "Bitte geben Sie eine gültige USt-ID ein."
            }
          />
          <TextField
            label="Steuernummer (oder USt-ID)"
            value={form.taxno}
            mandatory
            fieldId="taxno"
            onChange={setValue}
            validate={validate}
            isValid={invalidFields.indexOf("taxno") === -1}
            errorMessage={
              noTaxInfo
                ? "Bitte geben Sie mindestens hier eine Steuernummer oder oben eine USt-ID ein."
                : "Bitte geben Sie eine gültige Steuernummer ein."
            }
          />
          <TextField
            label="Straße"
            value={form.street}
            mandatory
            fieldId="street"
            onChange={setValue}
            validate={validate}
            isValid={invalidFields.indexOf("street") === -1}
          />
          <TextField
            label="Nr."
            value={form.streetNumber}
            mandatory
            fieldId="streetNumber"
            onChange={setValue}
            validate={validate}
            isValid={invalidFields.indexOf("streetNumber") === -1}
          />
          <TextField
            label="Adresszusatz"
            value={form.co}
            fieldId="co"
            onChange={setValue}
          />
          <TextField
            label="PLZ"
            value={form.zipcode}
            mandatory
            fieldId="zipcode"
            onChange={setValue}
            validate={validate}
            isValid={invalidFields.indexOf("zipcode") === -1}
          />
          <TextField
            label="Ort"
            value={form.city}
            mandatory
            fieldId="city"
            onChange={setValue}
            validate={validate}
            isValid={invalidFields.indexOf("city") === -1}
          />
          <TextField
            disabled={true}
            fieldId="country"
            isValid={true}
            label="Land"
            mandatory
            onChange={setValue}
            validate={validate}
            value="Deutschland"
          />
        </Column>
        <Column>
          <Title label="Ansprechpartner" isSmall />
          <SelectWrapper>
            <Label>Anrede *</Label>
            <StyledSelectField
              options={selectOptions}
              onChange={handleSalutationChange}
              placeholder="Bitte wählen Sie Ihre Anrede"
              value={selectedSalutation}
            />
            {validate && invalidFields.indexOf("salutation") !== -1 && (
              <Error>Bitte wählen Sie eine gültige Anrede aus</Error>
            )}
          </SelectWrapper>
          <TextField
            label="Titel"
            value={form.title}
            fieldId="title"
            onChange={setValue}
            validate={false}
            isValid={invalidFields.indexOf("title") === -1}
          />
          <TextField
            label="Vorname"
            value={form.firstname}
            mandatory
            fieldId="firstname"
            onChange={setValue}
            validate={validate}
            isValid={invalidFields.indexOf("firstname") === -1}
          />
          <TextField
            label="Nachname"
            value={form.lastname}
            mandatory
            fieldId="lastname"
            onChange={setValue}
            validate={validate}
            isValid={invalidFields.indexOf("lastname") === -1}
          />
          <TextField
            label="E-mail"
            value={form.email}
            mandatory
            fieldId="email"
            onChange={setValue}
            validate={validate}
            isValid={invalidFields.indexOf("email") === -1}
            errorMessage="Das ist leider keine gültige E-mail Adresse."
          />
          <TextField
            label="E-mail Bestätigung"
            value={form.emailConfirm}
            mandatory
            fieldId="emailConfirm"
            onChange={setValue}
            validate={validate}
            isValid={invalidFields.indexOf("emailConfirm") === -1}
            errorMessage="Bitte geben Sie die gleiche E-mail erneut ein."
          />
          <TextField
            label="Telefon"
            value={form.phone}
            mandatory
            fieldId="phone"
            onChange={setValue}
            validate={validate}
            isValid={invalidFields.indexOf("phone") === -1}
          />
        </Column>
      </ColumnWrapper>
      <StyledCheckbox
        label="Ja, ich habe die <a href='https://www.pacvertise.de/datenschutzerklaerung' target='_blank'>Datenschutzerklärungen</a> gelesen und akzeptiere diese."
        name="accept-privacy"
        checked={acceptConditions}
        onSelect={(props: any) => {
          const value = props.target.checked;
          setAcceptConditions(value);
          setValue({ value, fieldId: "acceptDSE" });
        }}
      />
      {validate && invalidFields.indexOf("acceptDSE") !== -1 && (
        <Error>Bitte akzeptieren die die Datenschutzerklärung</Error>
      )}

      <FooterNavigation step="userData" onBeforeNext={validateAndSave} />
    </Fragment>
  );
};
