// Modules
import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";

// Custom
import theme from "./theme";

// Context
import { ContextProvider } from "./ContextProvider";

// Components
import { App } from "./App";

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <ContextProvider>
      <App />
    </ContextProvider>
  </ThemeProvider>,
  document.getElementById("root")
);
