// Modules
import React from "react";
import styled from "styled-components";

// Styles
const StyledLabel = styled.label`
  display: block;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  line-height: ${props => props.theme.textLineHeight};
  position: relative;
  font-size: 16px;
  line-height: 16px;

  &:hover {
    cursor: pointer;
  }
`;

const StyledInput = styled.input`
  width: 20px;
  opacity: 0;
  position: absolute;

  &:checked ~ span:after {
    display: block;
  }
`;

const FakeCheckbox = styled.span`
  position: relative;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: ${props => props.theme.light};
  border: 1px solid ${props => props.theme.dark};
  border-radius: 4px;
  margin-right: 20px;

  &:after {
    content: "";
    display: block;
    width: 3px;
    height: 6px;
    border: solid ${props => props.theme.primaryColor};
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    position: relative;
    top: 3px;
    left: 5px;
    display: none;
  }
`;

const StyledLabelName = styled.div`
  flex: 1;
`;

// Definitions
type Props = {
  label: string;
  name: string;
  className?: string;
  checked: boolean;
  onSelect?: any;
  value?: any;
};

// Component / Hook
export const Checkbox = (props: Props) => {
  const { value, label, name, onSelect, checked, className } = props;
  return (
    <StyledLabel className={className}>
      <StyledInput
        type="checkbox"
        name={name}
        value={value}
        onClick={onSelect}
        checked={checked}
        readOnly
        data-label={label}
      />
      <FakeCheckbox />
      <StyledLabelName dangerouslySetInnerHTML={{ __html: label }} />
    </StyledLabel>
  );
};
