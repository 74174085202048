// Modules
import React, { Fragment, useContext, useEffect, useState } from "react";
import styled from "styled-components";

// Context
import { AppContext } from "../../ContextProvider";

// Components
import { RadioButton } from "../RadioButton";
import { Loading } from "../Loading";
import { Intro } from "../Intro";
import { FooterNavigation } from "../FooterNavigation";
import { Title } from "../Title";
import { StickyHeaderContent } from "../../components/StickyHeaderContent";

// Styles
const Wrapper = styled.div``;
const SelectionWrapper = styled.div`
  margin-top: ${props => props.theme.defaultMargin};
`;

const RadioButtonWrapper = styled.div`
  margin-top: ${props => props.theme.defaultMargin};
`;

const StyledTitle = styled(Title)`
  margin: 0;
`;

// Component / Hook
export const ChoicePP = () => {
  const [isLoading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);

  const {
    dispatch,
    state: { advertisingMaterial },
  } = useContext(AppContext);

  const getConfig = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BACKEND}/codetypes`
    );
    const { data } = await response.json();

    setOptions(data);
    setLoading(false);
  };

  // Load data on start
  useEffect(() => {
    getConfig();
  }, []);

  const setActiveItem = (element: any) => {
    const codetype = parseInt(element.target.value, 10)
    dispatch({
      payload: {
        codetype,
        // @ts-ignore
        codetypeName: options.filter((item: any) => item.id === codetype)[0].codetype
      },
      step: "advertisingMaterial",
    });
  };

  const { format, codetype } = advertisingMaterial;

  // Check if next button is disabled
  const disabledNext = codetype === undefined || codetype < 0;

  let content = <Loading />;
  if (!isLoading) {
    content = (
      <Fragment>
        <StyledTitle label="Bitte wählen Sie die Art der Codierung" isSmall />
        <RadioButtonWrapper>
          {options.map((option: any) => (
            <RadioButton
              label={option.description}
              key={option.id}
              value={option.id}
              name="pp-option"
              onSelect={setActiveItem}
              selectedValue={codetype}
            />
          ))}
        </RadioButtonWrapper>
      </Fragment>
    );
  }

  const onBeforePrev = () =>
    new Promise(async resolve => {
      dispatch({
        stepAction: "reset",
        step: "advertisingMaterial",
      });

      // Answer correctly to footer navigation can move to next element
      resolve(true);
    });

  return (
    <Wrapper>
      <StickyHeaderContent>
        <StyledTitle label="Werbemittel" />
      </StickyHeaderContent>
      <Intro>Ich möchte Werbemittel im Format {format} drucken lassen.</Intro>
      <SelectionWrapper>{content}</SelectionWrapper>
      <FooterNavigation
        step="advertisingMaterial"
        errorText="Bitte wählen Sie mindestens eine Option aus."
        disabledNext={disabledNext}
        onBeforePrev={onBeforePrev}
      />
    </Wrapper>
  );
};
