// Modules
import React, { useContext } from "react";
import styled from "styled-components";
import { useResponsive } from "react-hooks-responsive";

// Context
import { AppContext } from "../../ContextProvider";

// Config
import { breakpoints } from "../../config";

// Components
import { Title } from "../Title";
import { Checkbox } from "../Checkbox";
import { Intro } from "../Intro";

// Styles
const StyledSection = styled.section<{ size: string }>`
  margin-bottom: ${props => props.theme.defaultMargin};
`;

const StyledIntro = styled(Intro)`
  margin-bottom: 20px;
`;

// Definitions
type Props = {
  name: string;
  title: string;
  description?: string;
  groups?: any;
};

// Component / Hook
export const TargetGroupSection = (props: Props) => {
  // Props
  const { title, name, groups, description } = props;
  const options = groups[name];

  // Context
  const {
    dispatch,
    state: { targetGroups },
  } = useContext(AppContext);

  // States
  const [size] = useResponsive(breakpoints);

  const onSelect = (options: any) => {
    const label = options.target.dataset.label;
    const value = parseInt(options.target.value, 10);
    const isChecked = options.target.checked;
    const currentSelected = targetGroups.selected;
    const currentSelectedValues = targetGroups.selectedValues;
    const index = currentSelected.indexOf(value);

    if (isChecked) {
      currentSelected.push(value);
      currentSelectedValues.push(label);
    } else {
      currentSelected.splice(index, 1);
      currentSelectedValues.splice(index, 1);
    }

    dispatch({
      payload: { selected: currentSelected },
      step: "targetGroups",
    });
  };

  return (
    <StyledSection size={size}>
      <Title label={title} isSmall />
      {description && <StyledIntro>{description}</StyledIntro>}
      {options.map((group: any) => {
        return (
          <Checkbox
            label={group.value}
            name={`${name}_${group.id}`}
            value={group.id}
            key={group.id}
            onSelect={onSelect}
            checked={targetGroups.selected.indexOf(group.id) > -1}
          />
        );
      })}
    </StyledSection>
  );
};
