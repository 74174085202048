// Modules
import React from "react";
import styled from "styled-components";
import { version } from "../../../package.json";

// Styles
const FooterContainer = styled.footer`
  text-align: center;
  padding: 40px 0px;
  background: ${props => props.theme.dark};
  color: ${props => props.theme.lightLink};
  text-align: center;
`;

// Styles without any component props
const StyledLink = styled.a`
  text-decoration: none;
  color: ${props => props.theme.lightLink};

  &:hover {
    color: ${props => props.theme.light};
  }
`;

const Version = styled.div`
  opacity: 0.8;
  font-size: 10px;
  margin-top: 5px;
`;

const Copyright = styled.div`
  text-align: center;
  margin-top: 5px;
`;

export const Footer = () => {
  return (
    <FooterContainer>
      <StyledLink href="https://www.pacvertise.de/impressum" target="_blank">
        Impressum
      </StyledLink>
      {" | "}
      <StyledLink
        href="https://www.pacvertise.de/datenschutzerklaerung"
        target="_blank"
      >
        Datenschutzerklärung
      </StyledLink>
      <Copyright>&copy; Pacvertise</Copyright>
      <Version>v{version}</Version>
    </FooterContainer>
  );
};
