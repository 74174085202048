// Modules
import React, { useContext } from "react";
import styled from "styled-components";

// Context
import { AppContext } from "../../ContextProvider";

// Components
import { Title } from "../Title";
import { Intro } from "../Intro";
import { FileUploader } from "./FileUploader";

// Styles
const Wrapper = styled.div``;
const StyledLink = styled.a`
  margin-top: ${props => props.theme.defaultMargin};
  display: inline-block;
`;

// Component / Hook
export const PrintTemplate = () => {
  // Context
  const {
    dispatch,
    state: {
      config,
      advertisingMaterial: { type, selectedId },
      userData,
      booking = { files: {} },
    },
  } = useContext(AppContext);

  const linkText = config.advertisingMaterial[type].find(
    (element: any) => element.id === selectedId
  ).link_printtemplate;

  // Helper
  const onChangePrintUpload = (url: string) => {
    const newFileState = { ...booking.files, ...{ print: url } };
    dispatch({
      payload: {
        files: newFileState,
      },
      step: "booking",
    });
  };

  return (
    <Wrapper>
      <Title label="Druckvorlage" isSmall />
      <Intro>Laden Sie bitte hier Ihre Druckvorlage hoch.</Intro>
      <FileUploader
        type="print-template"
        userId={userData.id}
        onChange={onChangePrintUpload}
        selected={booking.files.print}
        label="Druckvorlage"
      />
      <StyledLink href={linkText} target="_blank">
        Welche Vorgaben sind zu beachten?
      </StyledLink>
    </Wrapper>
  );
};
