// Modules
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { addDays, differenceInCalendarDays } from "date-fns";

// Context
import { AppContext } from "../../ContextProvider";

// Components
import { CustomDatePicker } from "../CustomDatePicker";
// import { Intro } from "../Intro";
import { ToolTip } from "../ToolTip";

// Styles
const SelectWrapper = styled.div`
  margin-bottom: ${props => props.theme.defaultMargin};
`;

const Error = styled.div`
  color: red;
  margin: 10px 0 20px 0;
`;

const StyledIntro = styled.span`
  margin-bottom: 0;
`;

// const Note = styled(Intro)`
//   margin-top: ${props => props.theme.defaultMargin};
// `;

// Helper
export const isValidStartDate = (selectedDate: any, minimum: any) => {
  return (
    differenceInCalendarDays(selectedDate, getMinimumStartDate(minimum)) >= 0
  );
};

export const getMinimumStartDate = (minimum: any) =>
  addDays(new Date(), minimum);

// Component / Hook
export const StartDate = (props: any) => {
  // Get information from props
  const { info } = props;

  // Get state information
  const { dispatch, state } = useContext(AppContext);
  const { startDate } = state.circulation;

  // States
  const currentDate = startDate
    ? new Date(startDate.date)
    : getMinimumStartDate(info.val_distribution);
  const [selectedDate, setSelectedDate] = useState(currentDate);

  const onChange = (selectedDate: Date) => {
    dispatch({
      payload: {
        note_distribution: info.note_distribution,
        note_validity: info.note_voucher_validity,
        note_delivery: info.note_delivery,
        startDate: {
          date: selectedDate,
        },
      },
      step: "circulation",
    });
    setSelectedDate(selectedDate);
  };

  let distirbutionNote = "";
  if (info.note_distribution) distirbutionNote = info.note_distribution;

  return (
    <SelectWrapper>
      <StyledIntro>
        Beginn der Streuung: <ToolTip tip={distirbutionNote} />
      </StyledIntro>
      <CustomDatePicker
        selectedDate={selectedDate}
        onChange={onChange}
        minDate={startDate.minDate}
      />
      {!startDate.isValid && !info.note_distribution && (
        <Error>Bitte überprüfen Sie Ihre Eingaben.</Error>
      )}
      {!startDate.isValid && info.note_distribution && (
        <Error>
          Bitte beachten Sie folgenden Hinweis:
          <br />
          {distirbutionNote}
        </Error>
      )}
    </SelectWrapper>
  );
};
