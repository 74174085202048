const REGEX_TAX_NO = [
  /^(28\d{2}0\d{3}\d{4})|(\d{2}\d{3}\/\d{4})\d$/, // Baden-Württemberg
  /^(9\d{3}0\d{3}\d{4})|(\d{3}\/\d{3}\/\d{4})\d$/, //Bayern
  /^(11\d{2}0\d{3}\d{4})|(\d{2}\/\d{3}\/\d{4})\d$/, //Berlin
  /^(30\d{2}0\d{3}\d{4})|(0\d{2}\/\d{3}\/\d{4})\d$/, //Brandenburg
  /^(24\d{2}0\d{3}\d{4})|(\d{2} \d{3} \d{4})\d$/, //Bremen
  /^(22\d{2}0\d{3}\d{4})|(\d{2}\/\d{3}\/\d{4})\d$/, //Hamburg
  /^(26\d{2}0\d{3}\d{4})|(0\d{2} \d{3} \d{4})\d$/, //Hessen
  /^(40\d{2}0\d{3}\d{4})|(0\d{2}\/\d{3}\/\d{4})\d$/, //Mecklenburg-Vorpommern
  /^(23\d{2}0\d{3}\d{4})|(\d{2}\/\d{3}\/\d{4})\d$/, //Niedersachsen
  /^(5\d{3}0\d{4}\d{3})|(\d{3}\/\d{3}\/\d{4})\d$/, //Nordrhein-Westfalen
  /^(27\d{2}0\d{3}\d{4})|(\d{2}\/\d{3}\/\d{4})\d$/, //Rheinland-Pfalz
  /^(10\d{2}0\d{3}\d{4})|(0\d{2}\/\d{3}\/\d{4})\d$/, //Saarland
  /^(32\d{2}0\d{3}\d{4})|(2\d{2}\/\d{3}\/\d{4})\d$/, //Sachsen
  /^(31\d{2}0\d{3}\d{4})|(1\d{2}\/\d{3}\/\d{4})\d$/, //Sachsen-Anhalt
  /^(21\d{2}0\d{3}\d{4})|(\d{2}\/\d{3}\/\d{4})\d$/, //Schleswig-Holstein
  /^(41\d{2}0\d{3}\d{4})|(1\d{2}\/\d{3}\/\d{4})\d$/, //Thüringen
];

export default (toCheck: string) =>
  REGEX_TAX_NO.some(regex => regex.test(toCheck));
