// Modules
import React, { Fragment, useContext, useState } from "react";
import styled from "styled-components";

// Context
import { AppContext } from "../../ContextProvider";

// Components
import { Checkbox } from "../../components/Checkbox";
import { Loading } from "../../components/Loading";
import { FooterNavigation } from "../../components/FooterNavigation";
import { PrintTemplate } from "../../components/Booking/PrintTemplate";
import { UploadVoucher } from "../../components/Booking/UploadVoucher";
import { Summary } from "../../components/Summary";
import { Title } from "../../components/Title";
import { StickyHeaderContent } from "../../components/StickyHeaderContent";

// Styles
const ColumnWrapper = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.largeMargin};
`;

const Column = styled.div`
  flex: 1;

  &:first-child {
    padding-right: 20px;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  &:last-child {
    margin-top: ${props => props.theme.largeMargin};
  }
`;

const SavingWrapper = styled.div`
  text-align: center;
`;

const entities: { [index: string]: string } = {
  amp: "&",
  auml: "ä",
  Auml: "Ä",
  öuml: "ö",
  Ouml: "Ö",
  uuml: "ü",
  Uuml: "Ü",
  szlig: "ß",
};

const fixEntities = (text: string) =>
  text.replace(
    /&([^;]*);/g,
    (match: string, value: string) => entities[value] || match
  );

// Helper
const checkDisabled = (
  booking: any,
  acceptAGB: boolean,
  type: string,
  codetype: number,
  prePayment: boolean
) => {
  let files = {
    print: "",
    voucher: "",
  };

  if (booking && booking.files) {
    files = booking.files;
  }

  // Validate print template
  if (type === "pp" && (!files.print || !files.print.length)) {
    return "Bitte laden Sie die Druckvorlage hoch.";
  }

  // Validate voucher codes
  if (
    (codetype === 1 || codetype === 2) &&
    (!files.voucher || !files.voucher.length)
  ) {
    return "Bitte laden Sie die Gutscheincodes hoch.";
  }

  // Validate if user accept agb
  if (!acceptAGB) {
    return "Akzeptieren Sie bitte unsere AGB.";
  }

  if (!prePayment) {
    return "Akzeptieren Sie bitte, dass Sie per Vorkasse bezahlen.";
  }

  return "";
};

// Component / Hook
export const Booking = () => {
  // States
  const [acceptAGB, setAcceptAGB] = useState(false);
  const [prePayment, setPrePayment] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  // Context
  const {
    dispatch,
    state: {
      userData,
      advertisingMaterial: {
        acceptDelivery,
        type,
        codetype,
        codetypeName,
        selectedId,
        format,
        weight,
      },
      targetGroups,
      shippingCategories,
      competitiveInformation,
      booking = {},
      circulation: {
        amount,
        amountId,
        discountAmount = 0,
        discountCode,
        distribution_tkp,
        note_delivery,
        note_distribution,
        note_validity,
        print_tkp = 0,
        startDate,
        voucherStart,
        feedback,
      },
    },
  } = useContext(AppContext);

  // Functions
  const saveBooking = async () => {
    return new Promise(async resolve => {
      setIsSaving(true);

      let printlayoutfile_link = "";
      if (type === "pp" && booking && booking.files && booking.files.print) {
        printlayoutfile_link = booking.files.print;
      }

      let codefile_link = "";
      if (
        (codetype === 1 || codetype === 2) &&
        booking &&
        booking.files &&
        booking.files.voucher
      ) {
        codefile_link = booking.files.voucher;
      }

      const postParams = {
        type,
        user_id: userData.id,
        accept_dse: userData.acceptDSE,
        accept_agb: acceptAGB,
        accept_deliverynote: acceptDelivery,
        ep_format_id: type === "ep" ? selectedId : null,
        pp_format_id: type === "pp" ? selectedId : null,
        codetype_id: codetype,
        codetype_name: codetypeName,
        printlayoutfile_link,
        codefile_link,
        targetgroup_id: targetGroups.selected.join(","),
        targetgroup: targetGroups.selectedValues.join("||"),
        shippingcategory_code: shippingCategories.selected.join(","),
        shippingcategory: shippingCategories.selectedValues.join("||"),
        competitorcategory_code: competitiveInformation.category,
        competitorcategory: competitiveInformation.categoryName,
        product: competitiveInformation.brand,
        amount,
        distributionstart: startDate.date,
        validity: voucherStart.date,
        note_delivery,
        note_validity,
        note_distribution,
        distribution_tkp,
        print_tkp,
        discountCode,
        discountAmount,
        feedback,
        amountId,
        format,
        weight,
      };

      const config = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postParams),
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_BACKEND}/orders`,
        config
      );

      const {
        error: saveError,
        createdWMID,
        orderId,
        totalGrossPrice,
        email,
        orderNumber,
      } = await response.json();

      if (saveError) {
        console.log("TCL: saveBooking -> saveError", saveError);
        alert(
          `Es ist leider ein Fehler aufgetreten${
            saveError.errorText
              ? `: ${fixEntities(saveError.errorText)} (${saveError.errorCode})`
              : "!"
          }`
        );
        setIsSaving(false);
        return;
      }

      dispatch({
        payload: {
          wmid: createdWMID,
          orderId,
          totalGrossPrice,
          email,
          orderNumber,
        },
        step: "booking",
      });

      dispatch({
        step: "confirmation",
        stepAction: "done",
      });

      // Answer correctly to footer navigation can move to next element
      resolve(true);
      // window.location.href = `${
      //   process.env.REACT_APP_API_BACKEND
      // }/payment?orderId=${orderId}`;
    });
  };

  // Check if everything is valid
  const errorText = checkDisabled(
    booking,
    acceptAGB,
    type,
    codetype,
    prePayment
  );

  if (isSaving) {
    return (
      <SavingWrapper>
        <Loading />
        Ihre Buchung wird bearbeitet...
      </SavingWrapper>
    );
  }

  return (
    <Fragment>
      <StickyHeaderContent>
        <Title label="Buchung" />
      </StickyHeaderContent>
      <ColumnWrapper>
        {type === "pp" && (
          <Column>
            <PrintTemplate />
          </Column>
        )}
        {(codetype === 1 || codetype === 2) && (
          <Column>
            <UploadVoucher />
          </Column>
        )}
      </ColumnWrapper>

      <Summary />

      <StyledCheckbox
        label="Ja, ich habe die <a href='https://www.paketplus.de/Werbepartner-AGB' target='_blank'>AGB</a> gelesen und stimme diesen zu."
        name="accept-agb"
        onSelect={(element: any) => setAcceptAGB(element.target.checked)}
        checked={acceptAGB}
      />
      <StyledCheckbox
        label="Ja, ich werde zeitnah die Bezahlung per Vorkasse durchführen."
        name="accept-prepayment"
        onSelect={(element: any) => setPrePayment(element.target.checked)}
        checked={prePayment}
      />
      <FooterNavigation
        step="booking"
        disabledNext={errorText.length > 0}
        nextLabel="Jetzt buchen"
        onBeforeNext={saveBooking}
        errorText={errorText}
      />
    </Fragment>
  );
};
