// Modules
import React, { Fragment, useState, useEffect, useContext } from "react";
import styled from "styled-components";

// Context
import { AppContext } from "../../ContextProvider";

// Components
import { FooterNavigation } from "../../components/FooterNavigation";
import { Intro } from "../../components/Intro";
import { Loading } from "../../components/Loading";
import { SelectField } from "../../components/SelectField";
import { TextField } from "../../components/TextField";
import { Title } from "../../components/Title";
import { StickyHeaderContent } from "../../components/StickyHeaderContent";

const StyledIntro = styled(Intro)`
  margin-bottom: 10px;
`;

const StyledTextField = styled(TextField)`
  max-width: 50%;
`;

const StyledSelectField = styled(SelectField)`
  margin-bottom: 40px;
`;

const ContentWrapper = styled.div`
  margin-top: 70px;
`;

// Component / Hook
export const CompetitiveInformation = () => {
  const {
    dispatch,
    state: { competitiveInformation },
  } = useContext(AppContext);

  const [isLoading, setLoading] = useState(true);
  const [brand, setBrand] = useState(competitiveInformation.brand || "");
  const [categories, setCategories] = useState([]);
  const step = "competitiveInformation";

  const loadCategories = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BACKEND}/top-categories`
    );
    const { data } = await response.json();
    setCategories(data);
    setLoading(false);
  };

  // Load data on start
  useEffect(() => {
    loadCategories();
  }, []);

  let selectOptions;
  let selectedCategory;
  if (!isLoading) {
    selectOptions = categories.map((category: any) => {
      const item = {
        value: category.id,
        label: category.name,
        code: category.code,
      };

      if (category.code === competitiveInformation.category) {
        selectedCategory = item;
      }
      return item;
    });
  }

  const onChangeSelect = (options: any) => {
    const payload: any = {
      category: options.code,
      categoryName: options.label,
    };

    dispatch({
      payload,
      step,
    });
  };

  const onChangeText = (props: any) => {
    const { value } = props;
    setBrand(value);

    const payload: any = {
      brand: value,
    };

    dispatch({
      payload,
      step,
    });
  };

  // Brand is optional
  const disabledNext = !competitiveInformation.category;

  return (
    <Fragment>
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <StickyHeaderContent>
              <Title label="Beworbenes Angebot" />
            <Intro>
              Wir werden die Verteilung Ihrer Paketbeilagen so organisieren,
              dass sie nicht zusammen mit Wettbewerbsangeboten gestreut werden.
              Dazu benötigen wir folgende Angaben von Ihnen:
            </Intro>
          </StickyHeaderContent>
          <ContentWrapper>
            <Title label="Kategorie" isSmall />
            <StyledIntro>
              Bitte wählen Sie die Kategorie, der Ihr Angebot zuzuordnen ist:
            </StyledIntro>
            <StyledSelectField
              options={selectOptions}
              onChange={onChangeSelect}
              placeholder="Bitte wählen Sie die Kategorie aus"
              value={selectedCategory}
            />
            <Title label="Beworbene Marke/ beworbenes Produkt" isSmall />
            <StyledIntro>
              Bitte nennen Sie die Marke und/oder das Produkt, das beworben
              werden soll:
            </StyledIntro>
            <StyledTextField
              placeholder="Marke / Produkt"
              fieldId="brand"
              onChange={onChangeText}
              value={brand}
            />
            <FooterNavigation
              step="competitiveInformation"
              errorText="Bitte wählen Sie eine Kategorie aus."
              disabledNext={disabledNext}
            />
          </ContentWrapper>
        </Fragment>
      )}
    </Fragment>
  );
};
