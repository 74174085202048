// Modules
import React, { Fragment, useState, useEffect, useContext } from "react";
import styled from "styled-components";

// Context
import { AppContext } from "../../ContextProvider";

// Components
import { Intro } from "../Intro";
import { Loading } from "../Loading";
import { SelectField } from "../SelectField";

// Definitions
interface IProps {
  className?: string;
}

// Styles
const SelectWrapper = styled.div`
  margin-bottom: ${props => props.theme.defaultMargin};
`;

// Helper
const formatOptions = (options: any) => {
  return options.map((item: any) => {
    const { id, amount, distribution_tkp, print_tkp, discountCode } = item;
    const option = {
      value: id,
      amountId: id,
      label: amount.toLocaleString(),
      amount,
      distribution_tkp,
      print_tkp,
      discountCode,
    };

    return option;
  });
};

// Component / Hook
export const CirculationSelect = (props: IProps) => {
  // Props
  const { className } = props;

  // Get state information
  const { dispatch, state } = useContext(AppContext);

  // States
  const [isLoading, setLoading] = useState(true);
  const [selected, setSelected] = useState();
  const [selectOptions, setSelectOptions] = useState([]);

  // Data fetching
  const loadAmountValues = async () => {
    // Get the type to define how to go on
    const { selectedId, type, weight, codetype } = state.advertisingMaterial;

    // Set query params depends on which flow type we have
    const queryParams =
      type === "ep" ? `weight=${weight}` : `codetype=${codetype}`;

    const response = await fetch(
      `${
        process.env.REACT_APP_API_BACKEND
      }/amount?type=${type}&id=${selectedId}&${queryParams}`
    );
    const { data } = await response.json();

    // Format
    setSelectOptions(formatOptions(data));
    setLoading(false);
  };

  // Load data on start
  useEffect(() => {
    loadAmountValues();
  }, []);

  // This will only happend once after data was loaded and formated
  useEffect(() => {
    let selected = selectOptions.find(
      (option: any) => option.amountId === state.circulation.amountId
    );

    // Check if there is nothing selected then try to get 10.000
    if (!selected)
      selected = selectOptions.find((option: any) => option.amount === 10000);

    // As a last default use the first item
    if (!selected) selected = selectOptions[0];

    setSelected(selected);

    // Dispatch new selected
    dispatch({
      payload: selected,
      step: "circulation",
    });
  }, [selectOptions]);

  // Save changes
  const onChangeSelect = (payload: any) => {
    dispatch({
      payload,
      step: "circulation",
    });
    setSelected(payload);
  };

  return (
    <Fragment>
      {isLoading ? (
        <Loading />
      ) : (
        <SelectWrapper className={className}>
          <Intro>Bitte wählen Sie die Auflage für Ihre PAC Ad Kampagne:</Intro>
          <SelectField
            options={selectOptions}
            onChange={onChangeSelect}
            placeholder="Anzahl der Auflage"
            value={selected}
          />
        </SelectWrapper>
      )}
    </Fragment>
  );
};
