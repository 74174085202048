// Modules
import React, { useContext } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

// Context
import { AppContext } from "../../ContextProvider";

// Screens
import { AdvertisingMaterial } from "../../screens/AdvertisingMaterial";
import { Booking } from "../../screens/Booking";
import { Circulation } from "../../screens/Circulation";
import { CompetitiveInformation } from "../../screens/CompetitiveInformation";
import { Confirmation } from "../../screens/Confirmation";
import { ShippingCategories } from "../../screens/ShippingCategories";
import { TargetGroup } from "../../screens/TargetGroup";
import { UserData } from "../../screens/UserData";

// Components
import NavigationItem from "./NavigationItem";

// Container
import { MainContainer } from "../../containers/Main";

// Styles
const NavigationContainer = styled.nav`
  padding: 30px 0;
  display: flex;
  position: fixed;
  top: 125px;
  left: 0;
  right: 0;
  z-index: 2;
  background: ${props => props.theme.light};
  border-top: 1px solid ${props => props.theme.borderColor};
`;

// Setup
export const menuItems = [
  {
    name: "Werbemittel",
    key: "advertisingMaterial",
    to: "/",
    component: AdvertisingMaterial,
    exact: true,
  },
  {
    name: "Zielgruppe",
    key: "targetGroups",
    to: "/targetgroups",
    component: TargetGroup,
  },
  {
    name: "Versandkategorien",
    key: "shippingCategories",
    to: "/shipping-categories",
    component: ShippingCategories,
  },
  {
    name: "Beworbenes Angebot",
    key: "competitiveInformation",
    to: "/competitive-information",
    component: CompetitiveInformation,
  },
  {
    name: "Auflage & Streustart",
    key: "circulation",
    to: "/circulation",
    component: Circulation,
  },
  {
    name: "Ihre Daten",
    key: "userData",
    to: "/userdata",
    component: UserData,
  },
  {
    name: "Buchung",
    key: "booking",
    to: "/booking",
    component: Booking,
  },
  {
    name: "Bestätigung",
    key: "confirmation",
    to: "/confirmation",
    component: Confirmation,
  },
];

// Component / Hook
const Navigation = (props: any) => {
  const {
    state: { finishedSections },
  } = useContext(AppContext);

  const activeIndex = menuItems.findIndex(
    element => element.to === props.location.pathname
  );

  return (
    <MainContainer>
      <NavigationContainer>
        {menuItems.map(({ to, key, name }, index) => {
          return (
            <NavigationItem
              to={to}
              key={key}
              name={name}
              index={index}
              isClickable={index <= activeIndex}
              isFinal={activeIndex === menuItems.length - 1}
              isActive={index === activeIndex}
              isDone={finishedSections[key]}
            />
          );
        })}
      </NavigationContainer>
    </MainContainer>
  );
};

export default withRouter(props => <Navigation {...props} />);
