// Modules
import React from "react";
import styled from "styled-components";

// Styles
const StyledLabel = styled.label`
  display: block;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  line-height: ${props => props.theme.textLineHeight};
  position: relative;
  font-size: 16px;
  line-height: 26px;

  &:hover {
    cursor: pointer;
  }
`;

const FakeButton = styled.span`
  position: relative;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: ${props => props.theme.light};
  border: 1px solid ${props => props.theme.dark};
  border-radius: 50%;
  margin-right: 20px;

  &:after {
    top: 4px;
    left: 4px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: ${props => props.theme.primaryColor};
    content: "";
    position: absolute;
    display: none;
  }
`;

const StyledInput = styled.input`
  width: 20px;
  opacity: 0;
  position: absolute;

  &:checked ~ span:after {
    display: block;
  }
`;

const StyledLabelName = styled.div`
  flex: 1;
`;

// Definitions
type Props = {
  label: string;
  name: string;
  value: number | string;
  onSelect: any;
  selectedValue?: number | string;
};

// Component / Hook
export const RadioButton = (props: Props) => {
  const { label, value, name, onSelect, selectedValue } = props;

  return (
    <StyledLabel>
      <StyledInput
        type="radio"
        value={value}
        name={name}
        onClick={onSelect}
        checked={value === selectedValue}
        readOnly
      />
      <FakeButton />
      <StyledLabelName>{label}</StyledLabelName>
    </StyledLabel>
  );
};
