// Modules
import React, { Fragment, useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

// Context
import { AppContext } from "../../ContextProvider";

// Components
import { Intro } from "../../components/Intro";
import { Title } from "../../components/Title";
import { Loading } from "../../components/Loading";
import { FooterNavigation } from "../../components/FooterNavigation";
import { ShippingCategoriesGroup } from "../../components/ShippingCategories/ShippingCategoriesGroup";
import { StickyHeaderContent } from "../../components/StickyHeaderContent";

// Styles
const SelectionWrapper = styled.div`
  margin-top: 60px;
`;

// Component / Hook
const ShippingCategoriesBase = () => {
  const [isLoading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);

  const {
    state: { shippingCategories },
  } = useContext(AppContext);

  const loadCategories = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BACKEND}/shipping-categories`
    );
    const { data } = await response.json();

    setOptions(data);
    setLoading(false);
  };

  // Load data on start
  useEffect(() => {
    loadCategories();
  }, []);

  const disabledNext = !shippingCategories.selected.length;

  return (
    <Fragment>
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <StickyHeaderContent>
            <Title label="Versandkategorien" />
            <Intro>
              Für sämtliche unserer Versandpartner haben wir erfasst, in welchen
              Produktbereichen sie schwerpunktmäßig verkaufen. Bitte wählen Sie
              hier aus, in welchen Umfeldern Ihre Werbemittel gestreut werden
              sollen, und wir wählen optimal passende Versender aus unserem
              Netzwerk für Ihre PAC Ad Kampagne aus:
            </Intro>
          </StickyHeaderContent>
          <SelectionWrapper>
            {options.map((item: any) => (
              <ShippingCategoriesGroup
                title={item.name}
                sub={item.sub}
                key={`shipping_cat_${item.id}`}
                code={`${item.code}`}
              />
            ))}
            <FooterNavigation
              step="shippingCategories"
              disabledNext={disabledNext}
              errorText="Bitte wählen Sie mindestens eine Versandkategorie aus."
            />
          </SelectionWrapper>
        </Fragment>
      )}
    </Fragment>
  );
};

export const ShippingCategories = withRouter(ShippingCategoriesBase);
