// Modules
import React, { useContext, useEffect, Fragment } from "react";
import styled from "styled-components";

// Context
import { AppContext } from "../../ContextProvider";

// Components
import { Intro } from "../../components/Intro";
import { Title } from "../../components/Title";
import { StickyHeaderContent } from "../../components/StickyHeaderContent";

// Styles
const ThankYouSection = styled.section`
  margin-bottom: ${props => props.theme.largeMargin};
`;

// Helper
const getUrlParameter = (name: string) => {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  let regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  let results = regex.exec(window.location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};

// Helper
export const formatPrice = (number: number) =>
  new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  }).format(number);

// Component / Hook
export const Confirmation = () => {
  // Context
  const {
    dispatch,
    state: {
      booking: { orderNumber, email, totalGrossPrice },
    },
  } = useContext(AppContext);

  const type = getUrlParameter("type");

  if (type === "error") {
    return (
      <Fragment>
        <Title label="Fehler im Bezahlvorgang!" isSmall />
        <Intro>
          Leider ist ein Fehler im Bezahlvorgang aufgetreten. Bitte wenden Sie
          sich an team@pacvertise.de
        </Intro>
      </Fragment>
    );
  }

  // Load data only on start
  useEffect(() => {
    dispatch({ stepAction: "completed" });
  }, []);

  return (
    <Fragment>
      <StickyHeaderContent>
        <Title label="Herzlichen Dank!" />
      </StickyHeaderContent>
      <ThankYouSection>
        <Intro>
          Wir haben direkt eine Zusammenfassung der Bestelldaten an Ihre
          Email-Adresse {email} versendet.
        </Intro>
        <Title label="Wie geht es jetzt weiter?" isSmall />
        <Intro>
          Bitte überweisen Sie zeitnah und mit Angabe der Bestellnummer{" "}
          {orderNumber} den Rechnungsbetrag von {formatPrice(totalGrossPrice)}{" "}
          auf das Konto DE61&nbsp;1004&nbsp;0000&nbsp;0155&nbsp;6422&nbsp;02 der{" "}
          PaketPLUS Marketing GmbH bei der Commerzbank, Filiale Berlin 1.
          <br />
          <br />
          Ihr Auftrag wird in der Regel innerhalb weniger Werktage nach{" "}
          Zahlungseingang von uns geprüft und bestätigt. D.h. Sie werden dann{" "}
          bald eine weitere E-Mail von uns erhalten.
          <br />
          <br />
          Ihr PACVERTISE-Team
        </Intro>
      </ThankYouSection>
    </Fragment>
  );
};
