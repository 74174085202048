// Modules
import React, { useContext } from "react";
import styled from "styled-components";
import { useResponsive } from "react-hooks-responsive";

// Context
import { AppContext } from "../../ContextProvider";

// Config
import { breakpoints } from "../../config";

// Icons
import MaterialEP1 from "../Icons/MaterialEP1";
import MaterialEP2 from "../Icons/MaterialEP2";
import MaterialEP3 from "../Icons/MaterialEP3";
import MaterialPP1 from "../Icons/MaterialPP1";
import MaterialPP2 from "../Icons/MaterialPP2";
import MaterialPP3 from "../Icons/MaterialPP3";

// Styles
const ItemWrapper = styled.div<{ theme: any; size: string }>`
  flex: 1;
  max-width: ${props => (props.size === "md" ? "285px" : "100%")};
  margin-right: ${props => (props.size === "md" ? "20px" : "0")};
  color: ${props => props.theme.primaryColor};
`;

const StyledItem = styled.div<{ theme: any; size: string }>`
  border: 2px solid ${props => props.theme.greyLight};
  background: ${props => props.theme.greyLight};
  text-align: center;
  margin-bottom: 20px;
  border-radius: 4px;
  width: ${props => (props.size === "md" ? "285px" : "100%")};
  flex: ${props => (props.size === "md" ? 1 : "none")};
  height: 285px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    cursor: pointer;
    border-color: ${props => props.theme.primaryColor};
  }
`;

const MaterialLink = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;
  color: ${props => props.theme.primaryColor};
`;

const Link = styled.a`
  display: none; // Is not necessary at the moment
  text-align: center;
  width: 100%;
  text-decoration: none;
  color: ${props => props.theme.defaultTextColor};

  &:hover {
    color: ${props => props.theme.primaryColor};
    text-decoration: underline;
  }
`;

const Title = styled.div`
  margin-top: 10px;
`;

const IconWrapper = styled.div`
  height: 150px;
  align-items: flex-end;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

// Definitions
type Props = {
  item: {
    format: string;
    id: number;
    link_printtemplate?: string;
  };
  type: string;
};

const getIcon = (type: string, id: number) => {
  switch (true) {
    case type === "ep" && id === 1:
      return <MaterialEP1 />;
    case type === "ep" && id === 2:
      return <MaterialEP2 />;
    case type === "ep" && id === 3:
      return <MaterialEP3 />;
    case type === "pp" && id === 1:
      return <MaterialPP1 />;
    case type === "pp" && id === 2:
      return <MaterialPP2 />;
    case type === "pp" && id === 3:
      return <MaterialPP3 />;
    default:
      return <MaterialEP1 />;
  }
};

// Component / Hook
export const FormatItem = (props: Props) => {
  // Props
  const {
    item: { format, id, link_printtemplate },
    type,
  } = props;

  // Context
  const { dispatch } = useContext(AppContext);

  // States
  const [size] = useResponsive(breakpoints);

  const select = () => {
    dispatch({
      payload: {
        selectedId: id,
        format,
        type,
      },
      step: "advertisingMaterial",
    });
  };

  let linkItem;
  if (link_printtemplate) {
    linkItem = (
      <Link href={link_printtemplate} target="_blank">
        Vorgaben zum Druckformat
      </Link>
    );
  }

  return (
    <ItemWrapper size={size}>
      <StyledItem onClick={select} size={size}>
        <div>
          <IconWrapper>{getIcon(type, id)}</IconWrapper>
          <Title>{format}</Title>
        </div>
      </StyledItem>
      <MaterialLink>{linkItem}</MaterialLink>
    </ItemWrapper>
  );
};
